export const routes = [
  {
    path: '/',
    name: 'default-layout',
    component: () => import('/app/src/pages/index.vue'),
    children: [
      {
        path: '',
        name: 'main-dashboard',
        component: () => import('/app/src/pages/index/index.vue'),
        /* no children */
      },
      {
        path: 'absence-histories',
        /* internal name: '//absence-histories' */
        /* no component */
        children: [
          {
            path: ':absenceHistory',
            /* internal name: '//absence-histories/[absenceHistory]' */
            /* no component */
            children: [
              {
                path: 'detail',
                name: '//absence-histories/[absenceHistory]/detail',
                component: () => import('/app/src/pages/index/absence-histories/[absenceHistory]/detail.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'action-list',
        /* internal name: '//action-list' */
        /* no component */
        children: [
          {
            path: ':actionList',
            /* internal name: '//action-list/[actionList]' */
            /* no component */
            children: [
              {
                path: 'task-list',
                /* internal name: '//action-list/[actionList]/task-list' */
                /* no component */
                children: [
                  {
                    path: ':taskList',
                    /* internal name: '//action-list/[actionList]/task-list/[taskList]' */
                    /* no component */
                    children: [
                      {
                        path: 'execute',
                        name: '//action-list/[actionList]/task-list/[taskList]/execute',
                        component: () => import('/app/src/pages/index/action-list/[actionList]/task-list/[taskList]/execute.vue'),
                        /* no children */
                      },
                      {
                        path: 'view',
                        name: '//action-list/[actionList]/task-list/[taskList]/view',
                        component: () => import('/app/src/pages/index/action-list/[actionList]/task-list/[taskList]/view.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              }
            ],
          },
          {
            path: ':result',
            /* internal name: '//action-list/[result]' */
            /* no component */
            children: [
              {
                path: 'form',
                name: '//action-list/[result]/form',
                component: () => import('/app/src/pages/index/action-list/[result]/form.vue'),
                /* no children */
              },
              {
                path: 'survey',
                name: '//action-list/[result]/survey',
                component: () => import('/app/src/pages/index/action-list/[result]/survey.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'activity-list',
        /* internal name: '//activity-list' */
        /* no component */
        children: [
          {
            path: '',
            name: '//activity-list/',
            component: () => import('/app/src/pages/index/activity-list/index.vue'),
            /* no children */
          },
          {
            path: ':activityList',
            /* internal name: '//activity-list/[activityList]' */
            /* no component */
            children: [
              {
                path: 'task-list',
                /* internal name: '//activity-list/[activityList]/task-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//activity-list/[activityList]/task-list/',
                    component: () => import('/app/src/pages/index/activity-list/[activityList]/task-list/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        path: 'analytics',
        /* internal name: '//analytics' */
        /* no component */
        children: [
          {
            path: '',
            name: '//analytics/',
            component: () => import('/app/src/pages/index/analytics/index.vue'),
            /* no children */
          },
          {
            path: 'absence',
            /* internal name: '//analytics/absence' */
            /* no component */
            children: [
              {
                path: 'details',
                name: '//analytics/absence/details',
                component: () => import('/app/src/pages/index/analytics/absence/details.vue'),
                /* no children */
              },
              {
                path: 'table',
                name: '//analytics/absence/table',
                component: () => import('/app/src/pages/index/analytics/absence/table.vue'),
                /* no children */
              }
            ],
          },
          {
            path: 'hr',
            /* internal name: '//analytics/hr' */
            /* no component */
            children: [
              {
                path: 'details',
                name: '//analytics/hr/details',
                component: () => import('/app/src/pages/index/analytics/hr/details.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'chat',
        /* internal name: '//chat' */
        /* no component */
        children: [
          {
            path: '',
            name: '//chat/',
            component: () => import('/app/src/pages/index/chat/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'clients',
        /* internal name: '//clients' */
        /* no component */
        children: [
          {
            path: '',
            name: '//clients/',
            component: () => import('/app/src/pages/index/clients/index.vue'),
            /* no children */
          },
          {
            path: ':client',
            /* internal name: '//clients/[client]' */
            /* no component */
            children: [
              {
                path: 'action-list',
                /* internal name: '//clients/[client]/action-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//clients/[client]/action-list/',
                    component: () => import('/app/src/pages/index/clients/[client]/action-list/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':actionList',
                    /* internal name: '//clients/[client]/action-list/[actionList]' */
                    /* no component */
                    children: [
                      {
                        path: 'task-list',
                        /* internal name: '//clients/[client]/action-list/[actionList]/task-list' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '//clients/[client]/action-list/[actionList]/task-list/',
                            component: () => import('/app/src/pages/index/clients/[client]/action-list/[actionList]/task-list/index.vue'),
                            /* no children */
                          },
                          {
                            path: ':taskList',
                            /* internal name: '//clients/[client]/action-list/[actionList]/task-list/[taskList]' */
                            /* no component */
                            children: [
                              {
                                path: 'execute',
                                name: '//clients/[client]/action-list/[actionList]/task-list/[taskList]/execute',
                                component: () => import('/app/src/pages/index/clients/[client]/action-list/[actionList]/task-list/[taskList]/execute.vue'),
                                /* no children */
                              }
                            ],
                          }
                        ],
                      }
                    ],
                  }
                ],
              },
              {
                path: 'activity-list',
                /* internal name: '//clients/[client]/activity-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//clients/[client]/activity-list/',
                    component: () => import('/app/src/pages/index/clients/[client]/activity-list/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'current-absence',
                /* internal name: '//clients/[client]/current-absence' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//clients/[client]/current-absence/',
                    component: () => import('/app/src/pages/index/clients/[client]/current-absence/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'dashboard',
                name: 'client-dashboard',
                component: () => import('/app/src/pages/index/clients/[client]/dashboard.vue'),
                /* no children */
              },
              {
                path: 'departments',
                /* internal name: '//clients/[client]/departments' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//clients/[client]/departments/',
                    component: () => import('/app/src/pages/index/clients/[client]/departments/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':department',
                    /* internal name: '//clients/[client]/departments/[department]' */
                    /* no component */
                    children: [
                      {
                        path: 'current-absence',
                        name: '//clients/[client]/departments/[department]/current-absence',
                        component: () => import('/app/src/pages/index/clients/[client]/departments/[department]/current-absence.vue'),
                        /* no children */
                      },
                      {
                        path: 'dashboard',
                        name: '//clients/[client]/departments/[department]/dashboard',
                        component: () => import('/app/src/pages/index/clients/[client]/departments/[department]/dashboard.vue'),
                        /* no children */
                      },
                      {
                        path: 'persons',
                        name: '//clients/[client]/departments/[department]/persons',
                        component: () => import('/app/src/pages/index/clients/[client]/departments/[department]/persons.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'divisions',
                /* internal name: '//clients/[client]/divisions' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//clients/[client]/divisions/',
                    component: () => import('/app/src/pages/index/clients/[client]/divisions/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':division',
                    /* internal name: '//clients/[client]/divisions/[division]' */
                    /* no component */
                    children: [
                      {
                        path: 'current-absence',
                        name: '//clients/[client]/divisions/[division]/current-absence',
                        component: () => import('/app/src/pages/index/clients/[client]/divisions/[division]/current-absence.vue'),
                        /* no children */
                      },
                      {
                        path: 'dashboard',
                        name: '//clients/[client]/divisions/[division]/dashboard',
                        component: () => import('/app/src/pages/index/clients/[client]/divisions/[division]/dashboard.vue'),
                        /* no children */
                      },
                      {
                        path: 'departments',
                        name: '//clients/[client]/divisions/[division]/departments',
                        component: () => import('/app/src/pages/index/clients/[client]/divisions/[division]/departments.vue'),
                        /* no children */
                      },
                      {
                        path: 'persons',
                        name: '//clients/[client]/divisions/[division]/persons',
                        component: () => import('/app/src/pages/index/clients/[client]/divisions/[division]/persons.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'employers',
                /* internal name: '//clients/[client]/employers' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//clients/[client]/employers/',
                    component: () => import('/app/src/pages/index/clients/[client]/employers/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':employer',
                    /* internal name: '//clients/[client]/employers/[employer]' */
                    /* no component */
                    children: [
                      {
                        path: 'current-absence',
                        name: '//clients/[client]/employers/[employer]/current-absence',
                        component: () => import('/app/src/pages/index/clients/[client]/employers/[employer]/current-absence.vue'),
                        /* no children */
                      },
                      {
                        path: 'dashboard',
                        name: '//clients/[client]/employers/[employer]/dashboard',
                        component: () => import('/app/src/pages/index/clients/[client]/employers/[employer]/dashboard.vue'),
                        /* no children */
                      },
                      {
                        path: 'departments',
                        name: '//clients/[client]/employers/[employer]/departments',
                        component: () => import('/app/src/pages/index/clients/[client]/employers/[employer]/departments.vue'),
                        /* no children */
                      },
                      {
                        path: 'divisions',
                        name: '//clients/[client]/employers/[employer]/divisions',
                        component: () => import('/app/src/pages/index/clients/[client]/employers/[employer]/divisions.vue'),
                        /* no children */
                      },
                      {
                        path: 'persons',
                        name: '//clients/[client]/employers/[employer]/persons',
                        component: () => import('/app/src/pages/index/clients/[client]/employers/[employer]/persons.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'invoiced-activities',
                /* internal name: '//clients/[client]/invoiced-activities' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//clients/[client]/invoiced-activities/',
                    component: () => import('/app/src/pages/index/clients/[client]/invoiced-activities/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'persons',
                /* internal name: '//clients/[client]/persons' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//clients/[client]/persons/',
                    component: () => import('/app/src/pages/index/clients/[client]/persons/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':person',
                    /* internal name: '//clients/[client]/persons/[person]' */
                    /* no component */
                    children: [
                      {
                        path: 'absence-history',
                        name: '//clients/[client]/persons/[person]/absence-history',
                        component: () => import('/app/src/pages/index/clients/[client]/persons/[person]/absence-history.vue'),
                        /* no children */
                      },
                      {
                        path: 'dashboard',
                        name: '//clients/[client]/persons/[person]/dashboard',
                        component: () => import('/app/src/pages/index/clients/[client]/persons/[person]/dashboard.vue'),
                        /* no children */
                      },
                      {
                        path: 'employment-history',
                        name: '//clients/[client]/persons/[person]/employment-history',
                        component: () => import('/app/src/pages/index/clients/[client]/persons/[person]/employment-history.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'view',
                name: 'client-information',
                component: () => import('/app/src/pages/index/clients/[client]/view.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'contacts',
        /* internal name: '//contacts' */
        /* no component */
        children: [
          {
            path: '',
            name: '//contacts/',
            component: () => import('/app/src/pages/index/contacts/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'departments',
        /* internal name: '//departments' */
        /* no component */
        children: [
          {
            path: '',
            name: '//departments/',
            component: () => import('/app/src/pages/index/departments/index.vue'),
            /* no children */
          },
          {
            path: ':department',
            /* internal name: '//departments/[department]' */
            /* no component */
            children: [
              {
                path: 'action-list',
                /* internal name: '//departments/[department]/action-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//departments/[department]/action-list/',
                    component: () => import('/app/src/pages/index/departments/[department]/action-list/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':actionList',
                    /* internal name: '//departments/[department]/action-list/[actionList]' */
                    /* no component */
                    children: [
                      {
                        path: 'taskList',
                        /* internal name: '//departments/[department]/action-list/[actionList]/taskList' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '//departments/[department]/action-list/[actionList]/taskList/',
                            component: () => import('/app/src/pages/index/departments/[department]/action-list/[actionList]/taskList/index.vue'),
                            /* no children */
                          },
                          {
                            path: ':taskList',
                            /* internal name: '//departments/[department]/action-list/[actionList]/taskList/[taskList]' */
                            /* no component */
                            children: [
                              {
                                path: 'execute',
                                name: '//departments/[department]/action-list/[actionList]/taskList/[taskList]/execute',
                                component: () => import('/app/src/pages/index/departments/[department]/action-list/[actionList]/taskList/[taskList]/execute.vue'),
                                /* no children */
                              }
                            ],
                          }
                        ],
                      }
                    ],
                  }
                ],
              },
              {
                path: 'activity-list',
                /* internal name: '//departments/[department]/activity-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//departments/[department]/activity-list/',
                    component: () => import('/app/src/pages/index/departments/[department]/activity-list/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'current-absence',
                /* internal name: '//departments/[department]/current-absence' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//departments/[department]/current-absence/',
                    component: () => import('/app/src/pages/index/departments/[department]/current-absence/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'dashboard',
                name: 'department-dashboard',
                component: () => import('/app/src/pages/index/departments/[department]/dashboard.vue'),
                /* no children */
              },
              {
                path: 'detail',
                name: '//departments/[department]/detail',
                component: () => import('/app/src/pages/index/departments/[department]/detail.vue'),
                /* no children */
              },
              {
                path: 'invoiced-activities',
                /* internal name: '//departments/[department]/invoiced-activities' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//departments/[department]/invoiced-activities/',
                    component: () => import('/app/src/pages/index/departments/[department]/invoiced-activities/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'persons',
                /* internal name: '//departments/[department]/persons' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//departments/[department]/persons/',
                    component: () => import('/app/src/pages/index/departments/[department]/persons/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':person',
                    /* internal name: '//departments/[department]/persons/[person]' */
                    /* no component */
                    children: [
                      {
                        path: 'absence-history',
                        name: '//departments/[department]/persons/[person]/absence-history',
                        component: () => import('/app/src/pages/index/departments/[department]/persons/[person]/absence-history.vue'),
                        /* no children */
                      },
                      {
                        path: 'dashboard',
                        name: '//departments/[department]/persons/[person]/dashboard',
                        component: () => import('/app/src/pages/index/departments/[department]/persons/[person]/dashboard.vue'),
                        /* no children */
                      },
                      {
                        path: 'employment-history',
                        name: '//departments/[department]/persons/[person]/employment-history',
                        component: () => import('/app/src/pages/index/departments/[department]/persons/[person]/employment-history.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        path: 'divisions',
        /* internal name: '//divisions' */
        /* no component */
        children: [
          {
            path: '',
            name: '//divisions/',
            component: () => import('/app/src/pages/index/divisions/index.vue'),
            /* no children */
          },
          {
            path: ':division',
            /* internal name: '//divisions/[division]' */
            /* no component */
            children: [
              {
                path: 'action-list',
                /* internal name: '//divisions/[division]/action-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//divisions/[division]/action-list/',
                    component: () => import('/app/src/pages/index/divisions/[division]/action-list/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':actionList',
                    /* internal name: '//divisions/[division]/action-list/[actionList]' */
                    /* no component */
                    children: [
                      {
                        path: 'taskList',
                        /* internal name: '//divisions/[division]/action-list/[actionList]/taskList' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '//divisions/[division]/action-list/[actionList]/taskList/',
                            component: () => import('/app/src/pages/index/divisions/[division]/action-list/[actionList]/taskList/index.vue'),
                            /* no children */
                          },
                          {
                            path: ':taskList',
                            /* internal name: '//divisions/[division]/action-list/[actionList]/taskList/[taskList]' */
                            /* no component */
                            children: [
                              {
                                path: 'execute',
                                name: '//divisions/[division]/action-list/[actionList]/taskList/[taskList]/execute',
                                component: () => import('/app/src/pages/index/divisions/[division]/action-list/[actionList]/taskList/[taskList]/execute.vue'),
                                /* no children */
                              }
                            ],
                          }
                        ],
                      }
                    ],
                  }
                ],
              },
              {
                path: 'activity-list',
                /* internal name: '//divisions/[division]/activity-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//divisions/[division]/activity-list/',
                    component: () => import('/app/src/pages/index/divisions/[division]/activity-list/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'current-absence',
                /* internal name: '//divisions/[division]/current-absence' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//divisions/[division]/current-absence/',
                    component: () => import('/app/src/pages/index/divisions/[division]/current-absence/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'dashboard',
                name: 'division-dashboard',
                component: () => import('/app/src/pages/index/divisions/[division]/dashboard.vue'),
                /* no children */
              },
              {
                path: 'departments',
                /* internal name: '//divisions/[division]/departments' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//divisions/[division]/departments/',
                    component: () => import('/app/src/pages/index/divisions/[division]/departments/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':department',
                    /* internal name: '//divisions/[division]/departments/[department]' */
                    /* no component */
                    children: [
                      {
                        path: 'current-absence',
                        name: '//divisions/[division]/departments/[department]/current-absence',
                        component: () => import('/app/src/pages/index/divisions/[division]/departments/[department]/current-absence.vue'),
                        /* no children */
                      },
                      {
                        path: 'dashboard',
                        name: '//divisions/[division]/departments/[department]/dashboard',
                        component: () => import('/app/src/pages/index/divisions/[division]/departments/[department]/dashboard.vue'),
                        /* no children */
                      },
                      {
                        path: 'persons',
                        name: '//divisions/[division]/departments/[department]/persons',
                        component: () => import('/app/src/pages/index/divisions/[division]/departments/[department]/persons.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'detail',
                name: '//divisions/[division]/detail',
                component: () => import('/app/src/pages/index/divisions/[division]/detail.vue'),
                /* no children */
              },
              {
                path: 'invoiced-activities',
                /* internal name: '//divisions/[division]/invoiced-activities' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//divisions/[division]/invoiced-activities/',
                    component: () => import('/app/src/pages/index/divisions/[division]/invoiced-activities/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'persons',
                /* internal name: '//divisions/[division]/persons' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//divisions/[division]/persons/',
                    component: () => import('/app/src/pages/index/divisions/[division]/persons/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':person',
                    /* internal name: '//divisions/[division]/persons/[person]' */
                    /* no component */
                    children: [
                      {
                        path: 'absence-history',
                        name: '//divisions/[division]/persons/[person]/absence-history',
                        component: () => import('/app/src/pages/index/divisions/[division]/persons/[person]/absence-history.vue'),
                        /* no children */
                      },
                      {
                        path: 'dashboard',
                        name: '//divisions/[division]/persons/[person]/dashboard',
                        component: () => import('/app/src/pages/index/divisions/[division]/persons/[person]/dashboard.vue'),
                        /* no children */
                      },
                      {
                        path: 'employment-history',
                        name: '//divisions/[division]/persons/[person]/employment-history',
                        component: () => import('/app/src/pages/index/divisions/[division]/persons/[person]/employment-history.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        path: 'documents',
        /* internal name: '//documents' */
        /* no component */
        children: [
          {
            path: '',
            name: '//documents/',
            component: () => import('/app/src/pages/index/documents/index.vue'),
            /* no children */
          },
          {
            path: ':document',
            /* internal name: '//documents/[document]' */
            /* no component */
            children: [
              {
                path: 'view',
                name: '//documents/[document]/view',
                component: () => import('/app/src/pages/index/documents/[document]/view.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'employer-information',
        name: '//employer-information',
        component: () => import('/app/src/pages/index/employer-information.vue'),
        /* no children */
      },
      {
        path: 'employers',
        /* internal name: '//employers' */
        /* no component */
        children: [
          {
            path: '',
            name: '//employers/',
            component: () => import('/app/src/pages/index/employers/index.vue'),
            /* no children */
          },
          {
            path: ':employer',
            /* internal name: '//employers/[employer]' */
            /* no component */
            children: [
              {
                path: 'action-list',
                /* internal name: '//employers/[employer]/action-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//employers/[employer]/action-list/',
                    component: () => import('/app/src/pages/index/employers/[employer]/action-list/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':actionList',
                    /* internal name: '//employers/[employer]/action-list/[actionList]' */
                    /* no component */
                    children: [
                      {
                        path: 'taskList',
                        /* internal name: '//employers/[employer]/action-list/[actionList]/taskList' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '//employers/[employer]/action-list/[actionList]/taskList/',
                            component: () => import('/app/src/pages/index/employers/[employer]/action-list/[actionList]/taskList/index.vue'),
                            /* no children */
                          },
                          {
                            path: ':taskList',
                            /* internal name: '//employers/[employer]/action-list/[actionList]/taskList/[taskList]' */
                            /* no component */
                            children: [
                              {
                                path: 'execute',
                                name: '//employers/[employer]/action-list/[actionList]/taskList/[taskList]/execute',
                                component: () => import('/app/src/pages/index/employers/[employer]/action-list/[actionList]/taskList/[taskList]/execute.vue'),
                                /* no children */
                              }
                            ],
                          }
                        ],
                      }
                    ],
                  }
                ],
              },
              {
                path: 'activity-list',
                /* internal name: '//employers/[employer]/activity-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//employers/[employer]/activity-list/',
                    component: () => import('/app/src/pages/index/employers/[employer]/activity-list/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'current-absence',
                /* internal name: '//employers/[employer]/current-absence' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//employers/[employer]/current-absence/',
                    component: () => import('/app/src/pages/index/employers/[employer]/current-absence/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'dashboard',
                name: 'employer-dashboard',
                component: () => import('/app/src/pages/index/employers/[employer]/dashboard.vue'),
                /* no children */
              },
              {
                path: 'departments',
                /* internal name: '//employers/[employer]/departments' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//employers/[employer]/departments/',
                    component: () => import('/app/src/pages/index/employers/[employer]/departments/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':department',
                    /* internal name: '//employers/[employer]/departments/[department]' */
                    /* no component */
                    children: [
                      {
                        path: 'current-absence',
                        name: '//employers/[employer]/departments/[department]/current-absence',
                        component: () => import('/app/src/pages/index/employers/[employer]/departments/[department]/current-absence.vue'),
                        /* no children */
                      },
                      {
                        path: 'dashboard',
                        name: '//employers/[employer]/departments/[department]/dashboard',
                        component: () => import('/app/src/pages/index/employers/[employer]/departments/[department]/dashboard.vue'),
                        /* no children */
                      },
                      {
                        path: 'persons',
                        name: '//employers/[employer]/departments/[department]/persons',
                        component: () => import('/app/src/pages/index/employers/[employer]/departments/[department]/persons.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'divisions',
                /* internal name: '//employers/[employer]/divisions' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//employers/[employer]/divisions/',
                    component: () => import('/app/src/pages/index/employers/[employer]/divisions/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':division',
                    /* internal name: '//employers/[employer]/divisions/[division]' */
                    /* no component */
                    children: [
                      {
                        path: 'current-absence',
                        name: '//employers/[employer]/divisions/[division]/current-absence',
                        component: () => import('/app/src/pages/index/employers/[employer]/divisions/[division]/current-absence.vue'),
                        /* no children */
                      },
                      {
                        path: 'dashboard',
                        name: '//employers/[employer]/divisions/[division]/dashboard',
                        component: () => import('/app/src/pages/index/employers/[employer]/divisions/[division]/dashboard.vue'),
                        /* no children */
                      },
                      {
                        path: 'departments',
                        name: '//employers/[employer]/divisions/[division]/departments',
                        component: () => import('/app/src/pages/index/employers/[employer]/divisions/[division]/departments.vue'),
                        /* no children */
                      },
                      {
                        path: 'persons',
                        name: '//employers/[employer]/divisions/[division]/persons',
                        component: () => import('/app/src/pages/index/employers/[employer]/divisions/[division]/persons.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'imports',
                /* internal name: '//employers/[employer]/imports' */
                /* no component */
                children: [
                  {
                    path: 'absence',
                    name: '//employers/[employer]/imports/absence',
                    component: () => import('/app/src/pages/index/employers/[employer]/imports/absence.vue'),
                    /* no children */
                  },
                  {
                    path: 'employees',
                    name: '//employers/[employer]/imports/employees',
                    component: () => import('/app/src/pages/index/employers/[employer]/imports/employees.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'invoiced-activities',
                /* internal name: '//employers/[employer]/invoiced-activities' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//employers/[employer]/invoiced-activities/',
                    component: () => import('/app/src/pages/index/employers/[employer]/invoiced-activities/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'persons',
                /* internal name: '//employers/[employer]/persons' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//employers/[employer]/persons/',
                    component: () => import('/app/src/pages/index/employers/[employer]/persons/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':person',
                    /* internal name: '//employers/[employer]/persons/[person]' */
                    /* no component */
                    children: [
                      {
                        path: 'absence-history',
                        name: '//employers/[employer]/persons/[person]/absence-history',
                        component: () => import('/app/src/pages/index/employers/[employer]/persons/[person]/absence-history.vue'),
                        /* no children */
                      },
                      {
                        path: 'dashboard',
                        name: '//employers/[employer]/persons/[person]/dashboard',
                        component: () => import('/app/src/pages/index/employers/[employer]/persons/[person]/dashboard.vue'),
                        /* no children */
                      },
                      {
                        path: 'employment-history',
                        name: '//employers/[employer]/persons/[person]/employment-history',
                        component: () => import('/app/src/pages/index/employers/[employer]/persons/[person]/employment-history.vue'),
                        /* no children */
                      }
                    ],
                  }
                ],
              },
              {
                path: 'view',
                name: 'employer-information',
                component: () => import('/app/src/pages/index/employers/[employer]/view.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'notifications',
        /* internal name: '//notifications' */
        /* no component */
        children: [
          {
            path: '',
            name: '//notifications/',
            component: () => import('/app/src/pages/index/notifications/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'persons',
        /* internal name: '//persons' */
        /* no component */
        children: [
          {
            path: '',
            name: '//persons/',
            component: () => import('/app/src/pages/index/persons/index.vue'),
            /* no children */
          },
          {
            path: ':person',
            /* internal name: '//persons/[person]' */
            /* no component */
            children: [
              {
                path: 'absence-history',
                name: '//persons/[person]/absence-history',
                component: () => import('/app/src/pages/index/persons/[person]/absence-history.vue'),
                /* no children */
              },
              {
                path: 'action-list',
                /* internal name: '//persons/[person]/action-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//persons/[person]/action-list/',
                    component: () => import('/app/src/pages/index/persons/[person]/action-list/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':actionList',
                    /* internal name: '//persons/[person]/action-list/[actionList]' */
                    /* no component */
                    children: [
                      {
                        path: 'taskList',
                        /* internal name: '//persons/[person]/action-list/[actionList]/taskList' */
                        /* no component */
                        children: [
                          {
                            path: '',
                            name: '//persons/[person]/action-list/[actionList]/taskList/',
                            component: () => import('/app/src/pages/index/persons/[person]/action-list/[actionList]/taskList/index.vue'),
                            /* no children */
                          },
                          {
                            path: ':taskList',
                            /* internal name: '//persons/[person]/action-list/[actionList]/taskList/[taskList]' */
                            /* no component */
                            children: [
                              {
                                path: 'execute',
                                name: '//persons/[person]/action-list/[actionList]/taskList/[taskList]/execute',
                                component: () => import('/app/src/pages/index/persons/[person]/action-list/[actionList]/taskList/[taskList]/execute.vue'),
                                /* no children */
                              }
                            ],
                          }
                        ],
                      }
                    ],
                  }
                ],
              },
              {
                path: 'activities',
                /* internal name: '//persons/[person]/activities' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//persons/[person]/activities/',
                    component: () => import('/app/src/pages/index/persons/[person]/activities/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':activity',
                    /* internal name: '//persons/[person]/activities/[activity]' */
                    /* no component */
                    children: [
                      {
                        path: 'location',
                        name: '//persons/[person]/activities/[activity]/location',
                        component: () => import('/app/src/pages/index/persons/[person]/activities/[activity]/location.vue'),
                        /* no children */
                      },
                      {
                        path: 'locations',
                        /* internal name: '//persons/[person]/activities/[activity]/locations' */
                        /* no component */
                        children: [
                          {
                            path: ':location',
                            /* internal name: '//persons/[person]/activities/[activity]/locations/[location]' */
                            /* no component */
                            children: [
                              {
                                path: 'availabilities',
                                name: '//persons/[person]/activities/[activity]/locations/[location]/availabilities',
                                component: () => import('/app/src/pages/index/persons/[person]/activities/[activity]/locations/[location]/availabilities.vue'),
                                /* no children */
                              }
                            ],
                          }
                        ],
                      }
                    ],
                  }
                ],
              },
              {
                path: 'activity-list',
                /* internal name: '//persons/[person]/activity-list' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//persons/[person]/activity-list/',
                    component: () => import('/app/src/pages/index/persons/[person]/activity-list/index.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'dashboard',
                name: 'person-dashboard',
                component: () => import('/app/src/pages/index/persons/[person]/dashboard.vue'),
                /* no children */
              },
              {
                path: 'detail',
                name: '//persons/[person]/detail',
                component: () => import('/app/src/pages/index/persons/[person]/detail.vue'),
                /* no children */
              },
              {
                path: 'employment-history',
                /* internal name: '//persons/[person]/employment-history' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//persons/[person]/employment-history/',
                    component: () => import('/app/src/pages/index/persons/[person]/employment-history/index.vue'),
                    /* no children */
                  },
                  {
                    path: ':employment',
                    /* internal name: '//persons/[person]/employment-history/[employment]' */
                    /* no component */
                    children: [
                      {
                        path: 'edit',
                        name: '//persons/[person]/employment-history/[employment]/edit',
                        component: () => import('/app/src/pages/index/persons/[person]/employment-history/[employment]/edit.vue'),
                        /* no children */
                      }
                    ],
                  },
                  {
                    path: 'create',
                    name: '//persons/[person]/employment-history/create',
                    component: () => import('/app/src/pages/index/persons/[person]/employment-history/create.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'invoiced-activities',
                /* internal name: '//persons/[person]/invoiced-activities' */
                /* no component */
                children: [
                  {
                    path: '',
                    name: '//persons/[person]/invoiced-activities/',
                    component: () => import('/app/src/pages/index/persons/[person]/invoiced-activities/index.vue'),
                    /* no children */
                  }
                ],
              }
            ],
          }
        ],
      },
      {
        path: 'settings',
        /* internal name: '//settings' */
        /* no component */
        children: [
          {
            path: '',
            name: '//settings/',
            component: () => import('/app/src/pages/index/settings/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'tasks',
        /* internal name: '//tasks' */
        /* no component */
        children: [
          {
            path: '',
            name: '//tasks/',
            component: () => import('/app/src/pages/index/tasks/index.vue'),
            /* no children */
          }
        ],
      }
    ],
    meta: {
      "requiresAuth": true
    }
  },
  {
    path: '/:all(.*)',
    name: '404',
    component: () => import('/app/src/pages/[...all].vue'),
    /* no children */
    meta: {
      "requiresAuth": false
    }
  },
  {
    path: '/auth',
    name: 'auth-layout',
    component: () => import('/app/src/pages/auth.vue'),
    children: [
      {
        path: 'forgot-password',
        name: '/auth/forgot-password',
        component: () => import('/app/src/pages/auth/forgot-password.vue'),
        /* no children */
      },
      {
        path: 'login',
        name: '/auth/login',
        component: () => import('/app/src/pages/auth/login.vue'),
        /* no children */
      },
      {
        path: 'reset-password',
        name: '/auth/reset-password',
        component: () => import('/app/src/pages/auth/reset-password.vue'),
        /* no children */
      },
      {
        path: 'verify-email',
        name: '/auth/verify-email',
        component: () => import('/app/src/pages/auth/verify-email.vue'),
        /* no children */
      }
    ],
    meta: {
      "requiresAuth": false
    }
  },
  {
    path: '/sso',
    name: '/sso',
    component: () => import('/app/src/pages/sso.vue'),
    /* no children */
    meta: {
      "requiresAuth": true
    }
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

